export const useABTests = () => {
  const route = useRoute();
  const mounted = ref(false);

  const appliedVariants = computed(() => {
    return Array.isArray(route.query.variant)
      ? route.query.variant
      : [route.query.variant].filter((a) => a).map((a) => a?.toLowerCase());
  });

  const formVariantsABTest = computed(() => {
    const showFull = appliedVariants.value.includes("fullform");
    const showStepsAlt = appliedVariants.value.includes("stepsalt");
    return {
      showFull,
      showSteps: !showFull && !showStepsAlt,
      showStepsAlt: showStepsAlt,
    };
  });

  const descriptionBeforeFormVsAfterFormABTest = computed(() => {
    const showBefore =
      appliedVariants.value.includes("descriptionbeforeform") && mounted.value;
    return {
      showBefore,
      showAfter: !showBefore,
    };
  });

  onMounted(() => {
    mounted.value = true;
  });

  return {
    formVariantsABTest,
    descriptionBeforeFormVsAfterFormABTest,
  };
};
